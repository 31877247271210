






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class BaseLoader extends Vue {
    @Prop({ required: false, type: Boolean }) protected fullScreen: boolean | undefined;
    @Prop({ required: false, type: Boolean }) protected noText: boolean | undefined;
}
