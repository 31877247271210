import { IPurchaseOrder } from '@/entity/purchase-order/purchase-order';
import { ICreatePurchaseOrder } from '@/entity/purchase-order/create-purchase-order';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';
import ApiBase from '@api/api-base';

class PurchaseOrderApi extends ApiBase<IPurchaseOrder> {
    /**
     *
     */
    constructor(urlBase: string = 'api/purchaseorders') {
        super(urlBase);
    }

    public async createPurchaseOrder(data: ICreatePurchaseOrder): Promise<ICancellableResult<string>> {
        return await ajaxCall.post<string, ICreatePurchaseOrder>(this.url, data);
    }

    public async getAllByProjectId(projectId: string): Promise<ICancellableResult<IPurchaseOrder[] | null>> {
        return await ajaxCall.baseAjaxCall<IPurchaseOrder[]>('get', this.url, { urlParameter: ['byproject', projectId] });
    }

    public async exportPurchaseOrderData(data: number[], sortKeySelected: string, isSortDesc: boolean): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, any>('post', this.url, {
            payload: {
                PurchaseOrderIdList: data,
                SortKey: sortKeySelected,
                isSortKeyDesc: isSortDesc,
            },
            urlParameter: ['exportPurchaseOrderData'],
            responseType: 'blob'
        });
    }
}

export const purchaseOrderApi: PurchaseOrderApi = new PurchaseOrderApi();
