import { ICreateInvoice } from '@/entity/invoice/create-invoice';
import { IInvoice } from '@/entity/invoice/invoice';
import { IModelInvoice } from '@/entity/pdf/pdf-model-invoice';
import { ISignature } from '@/entity/pdf/pdf-signature';
import ApiBase from '@api/api-base';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';

class InvoiceApi extends ApiBase<IInvoice> {
    /**
     *
     */
    constructor(urlBase: string = 'api/invoices') {
        super(urlBase);
    }

    public async createInvoice(data: ICreateInvoice): Promise<ICancellableResult<string>> {
        return await ajaxCall.post<string, ICreateInvoice>(this.url, data);
    }

    public async delete(invoiceId: string): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean>('delete', this.url, { urlParameter: [invoiceId] });
    }

    public async setIsGeneratedToTrue(invoiceId: string): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean>('post', this.url, { urlParameter: ['setisgenerated', invoiceId] });
    }

    public async getAllByProjectId(projectId: string): Promise<ICancellableResult<IInvoice[] | null>> {
        return await ajaxCall.baseAjaxCall<IInvoice[]>('get', this.url, { urlParameter: ['byproject', projectId] });
    }

    public async getAllModelInvoice(): Promise<ICancellableResult<IModelInvoice[]| null>> {
        return await ajaxCall.baseAjaxCall<IModelInvoice[]>('get', this.url, { urlParameter: ['allModelsInvoice'] });
    }

    public async getAllSigningInvoice(): Promise<ICancellableResult<ISignature[]| null>> {
        return await ajaxCall.baseAjaxCall<ISignature[]>('get', this.url, { urlParameter: ['allSignings'] });
    }

    public async exportInvoiceData(data: number[], sortKeySelected: string, isSortDesc: boolean): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, any>('post', this.url, {
            payload: {
                InvoiceIdList: data,
                SortKey: sortKeySelected,
                isSortKeyDesc: isSortDesc,
            },
            urlParameter: ['exportInvoiceData'],
            responseType: 'blob'
        });
    }
}

export const invoiceApi: InvoiceApi = new InvoiceApi();
