import { ReferentialStore } from './referential-store';
import Vue from 'vue';
import Vuex from 'vuex';
import { createProxy, extractVuexModule } from 'vuex-class-component';
import { AppStore } from './app-store';
import { ProjectStore } from './project-store';
import { EmployeeStore } from './employee-store';

Vue.use(Vuex);

// store.vuex.ts
export const store = new Vuex.Store({
    modules: {
        ...extractVuexModule(AppStore),
        ...extractVuexModule(ReferentialStore),
        ...extractVuexModule(ProjectStore),
        ...extractVuexModule(EmployeeStore)
    }
});

// Creating proxies.
export const vxm = {
    app: createProxy(store, AppStore),
    referential: createProxy(store, ReferentialStore),
    project: createProxy(store, ProjectStore),
    user: createProxy(store, EmployeeStore)
};
