import { IContract } from '@/entity/contract/contract';
import { IFee } from '@/entity/fee/fee';
import { IInvoice } from '@/entity/invoice/invoice';
import { IProject } from '@/entity/project/project';
import { IPurchaseOrder } from '@/entity/purchase-order/purchase-order';
import { IBackgroundImage } from '@/entity/shared/background-image';
import { contractApi } from '@/wapi/contract-api';
import { feeApi } from '@/wapi/fee-api';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { NU } from '@t/type';
import { action, createModule, mutation } from 'vuex-class-component';
import { invoiceApi } from '@/wapi/invoice-api';
import { projectApi } from '@/wapi/project-api';
import { purchaseOrderApi } from '@/wapi/purchase-order-api';

const VuexModule = createModule({
    namespaced: 'project',
    strict: true
});

export class ProjectStore extends VuexModule {
    private currentProject: NU<IProject> = {} as IProject;
    private currentContracts: IContract[] | null = [];
    private currentPurchaseOrders: IPurchaseOrder[] | null = [];
    private currentInvoices: IInvoice[] | null = [];
    private currentFees: IFee[] | null = [];
    private dropdownProjectOptions: IProject[] = [];
    private dropdownSelectedProject: NU<IProject> = null;

    get projectData(): NU<IProject> {
        return this.currentProject;
    }

    get dropdownProjectListOptions(): IProject[] {
        return this.dropdownProjectOptions;
    }

    get dropdownProject(): NU<IProject> {
        return this.dropdownSelectedProject;
    }

    get contractsList(): IContract[] | null {
        return this.currentContracts;
    }

    get purchaseOrderList(): IPurchaseOrder[] | null {
        return this.currentPurchaseOrders;
    }

    get invoicesList(): IInvoice[] | null {
        return this.currentInvoices;
    }

    get feesList(): IFee[] | null {
        return this.currentFees;
    }

    @action
    public async fetchProject(id: string): Promise<void> {
        const datas = await projectApi.getBase(id);
        if (isCallValidAndNotCancelled<IProject | null>(datas)) {
            const projectImageCallData = await projectApi.getBackgroundImageFromProject(Number(id));
            if (isCallValidAndNotCancelled<IBackgroundImage>(projectImageCallData)) {
                if (datas && datas.datas) {
                    if ((projectImageCallData.datas as unknown) === '') {
                        projectImageCallData.datas = { id: 0, img: '', title: '', type: '' };
                    }
                    datas.datas.backgroundImage = projectImageCallData.datas as IBackgroundImage;
                }
            }
            this.setProject(datas?.datas);
        }
    }

    @action
    public async updateProject(id: string): Promise<void> {
        const datas = await projectApi.getBase(id);
        if (isCallValidAndNotCancelled<IProject | null>(datas)) {
            this.setProject(datas?.datas);
        }
    }

    @action
    public async updateSelectedProject(id: string): Promise<void> {
        const datas = await projectApi.getBase(id);
        if (isCallValidAndNotCancelled<IProject | null>(datas)) {
            this.setDropDownProject(datas?.datas);
        }
    }

    @action
    public async fetchContracts(projectId: string): Promise<void> {
        const datas = await contractApi.getAllByProjectId(projectId);
        if (isCallValidAndNotCancelled<IContract[] | null>(datas)) {
            this.setContracts(datas?.datas);
        }
    }

    @action
    public async updateContracts(projectId: string): Promise<void> {
        const datas = await contractApi.getAllByProjectId(projectId);
        if (isCallValidAndNotCancelled<IContract[] | null>(datas)) {
            this.setContracts(datas?.datas);
        }
    }

    @action
    public async fetchPurchaseOrders(projectId: string): Promise<void> {
        const datas = await purchaseOrderApi.getAllByProjectId(projectId);
        if (isCallValidAndNotCancelled<IPurchaseOrder[] | null>(datas)) {
            this.setPurchaseOrders(datas?.datas);
        }
    }

    @action
    public async updatePurchaseOrders(projectId: string): Promise<void> {
        const datas = await purchaseOrderApi.getAllByProjectId(projectId);
        if (isCallValidAndNotCancelled<IPurchaseOrder[] | null>(datas)) {
            this.setPurchaseOrders(datas?.datas);
        }
    }

    @action
    public async fetchInvoices(projectId: string): Promise<void> {
        const datas = await invoiceApi.getAllByProjectId(projectId);
        if (isCallValidAndNotCancelled<IInvoice[] | null>(datas)) {
            this.setInvoices(datas?.datas);
        }
    }

    @action
    public async updateInvoices(projectId: string): Promise<void> {
        const datas = await invoiceApi.getAllByProjectId(projectId);
        if (isCallValidAndNotCancelled<IInvoice[] | null>(datas)) {
            this.setInvoices(datas?.datas);
        }
    }

    @action
    public async fetchFees(projectId: string): Promise<void> {
        if (this.currentFees && this.currentFees.length === 0) {
            const datas = await feeApi.getAllFeesByProjectId(projectId);
            if (isCallValidAndNotCancelled<IFee[] | null>(datas)) {
                this.setFees(datas?.datas);
            }
        }
    }

    @action
    public async updateIsSale(val: boolean): Promise<void> {
        this.setIsSale(val);
    }

    @action
    public async fetchDropDownProjectOptions(): Promise<void> {
        const datas = await projectApi.getAllFilteredByCurrentManager();
        if (isCallValidAndNotCancelled<IProject[]>(datas)) {
            datas?.datas?.forEach(element => {
                element.designation = element.designation?.toUpperCase();
            });
            this.setDropdownProjectOptions(datas?.datas ?? []);
        }
    }

    @mutation
    private setDropdownProjectOptions(state: IProject[]): void {
        this.dropdownProjectOptions = state;
    }

    @mutation
    private setProject(state: IProject | undefined | null): void {
        this.currentProject = state;
    }

    @mutation
    private setDropDownProject(state: IProject | undefined | null): void {
        this.dropdownSelectedProject = state;
    }

    @mutation
    private setContracts(state: IContract[] | null): void {
        this.currentContracts = state;
    }

    @mutation
    private setPurchaseOrders(state: IPurchaseOrder[] | null): void {
        this.currentPurchaseOrders = state;
    }

    @mutation
    private setInvoices(state: IInvoice[] | null): void {
        this.currentInvoices = state;
    }

    @mutation
    private setIsSale(state: boolean): void {
        if (this.projectData) this.projectData.isSale = state;
    }

    @mutation
    private setFees(state: IFee[] | null): void {
        this.currentFees = state;
    }

    @mutation
    public resetProject(): void {
        this.currentProject = null;
    }
}
